import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, startAfter, startAt,
  where, GeoPoint, Timestamp, setDoc, updateDoc
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {deleteObject, getStorage, getDownloadURL, getMetadata, listAll, ref as storageRef } from 'firebase/storage';
import {get, sumBy, orderBy as orderedBy, last, first, uniq, cloneDeep, unset, pickBy, identity} from 'lodash';
import nanoid from 'nanoid';
import {pushSnack} from '../app/state';
import {removeFromCart} from '../cart/state';
import {ConfirmationSource, Field, Fields} from '../model/fields';
import {Comp} from '../model/objects';
import {LEASE, RESIDENTIAL_RENT} from '../model/propertyTypes';
import MarkerImage from '../util/marker.png';

export const checkAddress = createAsyncThunk(
  'comp/checkAddress',
  async ({addr, type}, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const comps = collection(getFirestore(), 'orgs', org, 'comps');

      if (addr.street && addr.city) {
        let q = query(comps,
          where("type", "==", type),
          where("address.street", "==", addr.street),
          where("address.city", "==", addr.city));

        if (addr.unit) q = query(q, where("address.unit", "==", addr.unit));
        if (addr.region) q = query(q, where("address.region", "==", addr.region));

        const result = await getDocs(query(q, limit(1)));
        return result.docs.length > 0;
      }
      else {
        return false;
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const copyData = createAsyncThunk(
  'comp/copyData',
  async ({skipGroups=[]}, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    const comp = getState().comp.curr;

    try {
      const title = Comp.title(comp);

      const comps = collection(getFirestore(), 'orgs', org, 'comps')
      const q = query(comps,
        where(Field.TITLE.path, '==', title)
      );

      const [r1, r2] = await Promise.all([
        getDocs(query(q, orderBy(Field.SALES_DATE.path, "desc"), limit(1))),
        getDocs(query(q, limit(1)))
      ]);

      const oldComp = r1.docs.length > 0 ? r1.docs[0] : (r2.docs.length > 0 ? r2.docs[0] : null);
      if (oldComp) {
        return Comp.clone(oldComp.data(), skipGroups)
      }
      return null;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const renderMap = createAsyncThunk(
  'comp/renderMap',
  async (arg, {rejectWithValue}) => {
    const {marker} = (arg||{});
    try {
      // make it square
      const map = window.map;
      let canvas = map.getCanvas();
      let height = canvas.height;
      let width = canvas.width;
      let aspect = width / height.toFixed();
      let sx = aspect > 1 ? Math.floor((width - height) / 2.0) : 0;
      let sy = aspect < 1 ? Math.floor((height - width) / 2.0) : 0;
      let sw = aspect > 1 ? height : width;

      let thumb = document.createElement("canvas");
      thumb.width = 512;
      thumb.height = thumb.width;

      let g = thumb.getContext("2d");
      g.drawImage(canvas, sx, sy, sw, sw, 0, 0, thumb.width, thumb.height);

      const mapBounds = map.getBounds().toArray().flat();
      let thumbBounds;
      if (aspect > 1) {
        const bx = sx * (mapBounds[2] - mapBounds[0]) / width;
        thumbBounds = [
          mapBounds[0] + bx,
          mapBounds[1],
          mapBounds[2] - bx,
          mapBounds[3]
        ];
      }
      else {
        const by = sy * (mapBounds[3] - mapBounds[1]) / height;
        thumbBounds = [
          mapBounds[0],
          mapBounds[1] + by,
          mapBounds[2],
          mapBounds[3] - by
        ];
      }

      return await new Promise(resolve => {
        let img = new Image();
        img.onload = () => {
          if (marker) {
            const m = Comp.point(marker);
            if ((m[0] > thumbBounds[0]) &&
                (m[0] < thumbBounds[2]) &&
                (m[1] > thumbBounds[1]) &&
                (m[1] < thumbBounds[3])) {
              const x = (m[0] - thumbBounds[0]) / (thumbBounds[2] - thumbBounds[0]) * thumb.width;
              const y = (m[1] - thumbBounds[1]) / (thumbBounds[3] - thumbBounds[1]) * thumb.height;

              g.drawImage(img, x, y);
            }
          }
          else {
            g.drawImage(img, thumb.width / 2 - img.width / 2, thumb.height / 2 - img.height / 2);
          }

          resolve(thumb.toDataURL('image/jpeg'));
        };
        img.src = MarkerImage;
      });
    }
    catch(err) {
      return rejectWithValue(err);
    }

  }
);

const makeComp = (comp, auth) => {
  const now = new Date();
  const newComp = {
    ...cloneDeep(comp),
    // user: auth.user.uid,
    users: [auth.user.uid],
    title: Comp.title(comp),
    created: Timestamp.fromDate(now),
    created_by: {
      uid: auth.user.uid,
      name: auth.user.displayName,
      email: auth.user.email
    },
    updated: Timestamp.fromDate(now),
  };
  ConfirmationSource.setIfUnconfirmed(newComp, newComp);
  return newComp;
};

const deriveFields = (update, comp, dirty) => {
  const detached = comp.detached || [];
  uniq(dirty).forEach( f => {
    Fields.dependants(f).forEach(d => {
      if (!detached.includes(d.path)) {
        d.set(update, d.get(comp));
      }
    });
  });
};

export const addComp = createAsyncThunk(
  'comp/add',
  async (comp, {getState, dispatch, rejectWithValue}) => {
    const auth = getState().auth;
    const dirty = getState().comp.dirty;

    try {
      const newComp = makeComp(comp, auth);
      deriveFields(newComp, comp, dirty);

      const ref = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'comps'), newComp);
      dispatch(pushSnack({message: `Comp ${newComp.title} added.`}));
      return {ref, comp: newComp};
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadComp = createAsyncThunk(
  'comp/load',
  async (compId, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      return await getDoc(doc(getFirestore(), 'orgs', org, 'comps', compId))
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

export const saveComp = createAsyncThunk(
  'comp/save',
  async ({id, comp}, {dispatch, getState, rejectWithValue}) => {
    const auth = getState().auth;
    const dirty = getState().comp.dirty;

    try {
      // hack to patch expense ids
      if (comp.financial) {
        (comp.financial.expenses || []).forEach(e => {
          if (!e.id) e.id = nanoid();
        })
      }

      const users = [...(comp.users||[])];
      if (!users.includes(auth.user.uid)) {
        users.push(auth.user.uid);
      }

      const updated = Timestamp.fromDate(new Date());
      const updated_by = {
        uid: auth.user.uid,
        name: auth.user.displayName,
        email: auth.user.email
      };

      const update = { updated, updated_by, users }
      update.history = [{
        ...update,
        fields: uniq(dirty),
      }, ...(comp.history||[]) ]

      dirty.forEach( f => Fields.set(f, update, get(comp, f)));
      ConfirmationSource.setIfUnconfirmed(comp, update);
      deriveFields(update, comp, dirty);

      await setDoc(doc(getFirestore(), 'orgs', auth.org.id, 'comps', id), update, {merge: true});
      dispatch(pushSnack({message:`Comp ${comp.title} saved.`}));

      return {updated, updated_by};
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const addOrSaveComp = createAsyncThunk(
  'comp/addOrSave',
  (comp, {dispatch}) => {
    if (comp.id) {
      return dispatch(saveComp({id: comp.id, comp}));
    }
    else {
      return dispatch(addComp(comp));
    }
  }
)

export const deleteComp = createAsyncThunk(
  'comp/delete',
  async ({compId, comp}, {dispatch, getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await deleteDoc(doc(getFirestore(), 'orgs', org, 'comps', compId));
      dispatch(pushSnack({message: `Comp ${comp.title} deleted.`}));
      dispatch(removeFromCart(comp));
      return compId;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const cloneComp = createAsyncThunk(
  'comp/clone',
  async ({comp, cloneAsResale}, {dispatch, getState, rejectWithValue}) => {
    const auth = getState().auth;
    try {
      const skipGroups = comp.type === LEASE ? ['lease', 'confirmation'] :
        comp.type === RESIDENTIAL_RENT ? ['financial', 'confirmation'] : ['sales', 'confirmation']
      const newComp = makeComp(Comp.clone(comp, skipGroups), auth);
      skipGroups.forEach(g => delete newComp[g]);

      Field.SALES_IS_RESALE.set(newComp, cloneAsResale);

      const newCompRef = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'comps'), newComp);
      if ((newComp.photos||[]).length > 0 || (newComp.docs||[]).length > 0 ) {
        // copy over all files from storage
        const result = await httpsCallable(getFunctions(), 'copyCompFiles')({
          source: comp.id,
          target: newCompRef.id,
          org: auth.org.id
        });

        // update all of the objects
        if (result.data.status === 'success') {
          await Promise.all(result.data.files.map(f => {
            const ref = storageRef(getStorage(), f.url);
            return getDownloadURL(ref)
              .then(downloadUrl => {
                const o = newComp[f.type][f.index];
                o['url'] = downloadUrl;
                o['storageUrl'] = f.url;
              })
          }));

          const patch = {};
          if (newComp.photos) {
            patch.photos = newComp.photos;
          }
          if (newComp.docs) {
            patch.docs = newComp.docs;
          }
          if (Object.keys(patch).length > 0) {
            await updateDoc(newCompRef, patch);
          }

        } else {
          throw new Error(result.data);
        }
      }

      dispatch(pushSnack({message: `Comp ${newComp.title} cloned.`}));
      return {ref: newCompRef, comp: newComp};
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const addPhoto = createAsyncThunk(
  'comp/addPhoto',
  async ({url, comp}, {rejectWithValue}) => {
    try {
      const ref = storageRef(getStorage(), url);
      const [downloadUrl, metadata] = await Promise.all([getDownloadURL(ref), getMetadata(ref)]);
      const {size, contentType} = metadata;
      return {
        id: nanoid(),
        name: url.split("/").pop(),
        url: downloadUrl,
        storageUrl: url,
        updated: new Date(),
        mime: contentType,
        tags: [],
        size
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

export const removePhoto = createAsyncThunk(
  'comp/removePhoto',
  async (photo, {rejectWithValue}) => {
    try {
      try {
        // allow this to fail in the event the underlying file doesn't exist
        await deleteObject(storageRef(getStorage(), photo.storageUrl));
      }
      catch(err) {
        console.debug(err);
      }
      return photo;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadPhotos = createAsyncThunk(
  'comp/loadPhotos',
  async (compId, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await listAll(storageRef(getStorage(), `${org}/comps/${compId}/photos`));
      const list = [];
      for (const ref of result.items) {
        list.push({
          ref,
          url: await getDownloadURL(ref),
          meta: await getMetadata(ref)
        })
      }
      return list;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const addDocument = createAsyncThunk(
  'comp/addDoc',
  async (url, {rejectWithValue}) => {
    try {
      const ref = storageRef(getStorage(), url);
      const [downloadUrl, {name, size, contentType}] = await Promise.all([getDownloadURL(ref), getMetadata(ref)]);
      return {
        id: nanoid(),
        url: downloadUrl,
        storageUrl: url,
        updated: new Date(),
        mime: contentType,
        name,
        size
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

export const removeDocuments = createAsyncThunk(
  'comp/removeDocs',
  async (docs, {rejectWithValue}) => {
      try {
        for (let i = 0; i < docs.length; i++) {
          try {
            // allow this to fail in the event the underlying file doesn't exist
            await deleteObject(storageRef(getStorage(), docs[i].storageUrl));
          }
          catch(err) {
            console.debug(err)
          }
        }
        return docs;
      }
      catch(err) {
        return rejectWithValue(err);
      }
  }
);

export const loadDocuments = createAsyncThunk(
  'comp/loadDocs',
  async (compId, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await listAll(storageRef(getStorage(), `${org}/comps/${compId}/docs`));
      const list = [];
      for (const ref of result.items) {
        list.push({
          ref,
          url: await getDownloadURL(ref),
          meta: await getMetadata(ref)
        })
      }
      return list;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadRecentComps = createAsyncThunk(
  'comp/loadRecent',
  async (arg, {getState, rejectWithValue}) => {
    const {auth} = getState();
    try {
        const mine = await getDocs(query(
          collection(getFirestore(), 'orgs', auth.org.id, 'comps'),
          orderBy(Field.UPDATED.name, 'desc'),
          where("users", "array-contains", auth.user.uid),
          limit(10)
        ));
        const team = await getDocs(query(
          collection(getFirestore(), 'orgs', auth.org.id, 'comps'),
          orderBy(Field.UPDATED.name, 'desc'),
          limit(20)
        ));

        return {
          mine: mine.docs.map(Comp.create),
          team: team.docs.filter(d => !(d.get("users")||[]).includes(auth.user.uid)).map(Comp.create),
        }
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const pageComps = createAsyncThunk(
  'comp/page',
  async (forward, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    const list = getState().comp.list;
    try {
      const newPage = list.page+(forward?1:-1);

      let q = query(
        collection(getFirestore(), 'orgs', org, 'comps'),
        orderBy(list.sort.field, list.sort.order),
        limit(list.size)
      );

      for (const f of list.filters) {
        q = query(q, where(f.field, f.op, f.value));
      }

      if (list.page > -1) {
        if (forward) {
          q = query(q, startAfter(list.last));
        }
        else {
          q = query(q, startAt(last(list.stack)));
        }
      }

      return {
        page: newPage, docs: (await getDocs(q)).docs
      };
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

// TODO:?
// export const searchByWord = (word, auth, firebase) => {
//   return () => {
//     return firebase.firestore().collection(`orgs/${auth.org.id}/comps`)
//       .where("terms", "array-contains", word.toLowerCase())
//       .get()
//       .then(snap => {
//         return Promise.resolve(snap.docs.map(Comp.create));
//       });
//   };
// };

const comp = createSlice({
  name: 'comp',
  initialState: {
    curr: null,
    list: {
      page: -1,                           // page index
      size: 10,                           // page size
      values: [],                         // page contents
      last: null,
      stack: [],
      sort: {
        field: Field.UPDATED.path,
        order: "desc"
      },
      filters: []
    },
    recent: {
      mine: [],
      team: []
    },
    dirty: [],
    photos: [],
    docs: [],
    photo: 0,
    copyData: true,
    highlight: false,
    preview: {
      show: false,
      templates: null,
      template: null,
      view: 0
    },
    map: {
      moving: false,
    },
    error: null
  },
  reducers: {
    setComp: (state, action) => {
      state.curr = action.payload;
      state.photo = 0;
      state.dirty = [];
    },
    clearComp: (state, action) => {
      state.curr = null;
      state.dirty = [];
    },
    setType: (state, action) => {
      state.curr.type = action.payload;
      state.dirty.push('type');
    },
    setAddress: (state, action) => {
      // wrap in pickBy to remove null values
      state.curr.address = pickBy({
        ...state.curr.address,
        ...action.payload
      }, identity);
      state.dirty.push(...Object.keys(action.payload));
    },
    clearAddress: (state, action) => {
        state.curr.address = Comp.new().address;
    },
    setGeo: (state, action) => {
      const newGeo = "longitude" in action.payload
        ? [action.payload.longitude, action.payload.latitude] : action.payload.slice();
      state.curr.geo = new GeoPoint(...newGeo.reverse());
      state.dirty.push('geo');
    },
    clearGeo: (state, action) => {
        state.curr.geo = null;
    },

    setField: (state, action) => {
      const {field, value} = action.payload;
      field.update(value, state.curr);
      state.dirty.push(field.path);
    },
    clearField: (state, action) => {
        const {field} = action.payload;
        unset(state.curr, field);
        state.dirty.push(field);
    },
    setCopyData: (state, action) => {
      state.copyData = action.payload;
    },
    addNote: (state, action) => {
      state.curr.notes.splice(0, 0, action.payload);
      state.dirty.push(Field.NOTES.path);
    },
    removeNote: (state, action) => {
      const n = state.curr.notes.findIndex(it => it.id === action.payload.id);
      if (n > -1) {
        state.curr.notes.splice(n, 1);
        state.dirty.push(Field.NOTES.path);
      }
    },
    addExpense: (state, action) => {
      state.curr.financial.expenses.push({id: nanoid(), title: ''});
      state.dirty.push(Field.FINANCIAL_EXPENSES.path);
    },
    removeExpense: (state, action) => {
      const expense = action.payload;
      state.curr.financial.expenses = state.curr.financial.expenses.filter(it => it.id !== expense.id);
      state.dirty.push(Field.FINANCIAL_EXPENSES.path);
    },
    updateExpense: (state, action) => {
      const {index, expense} = action.payload;

      const grossIncome = Field.FINANCIAL_POTENTIAL_GROSS_INCOME.get(state.curr);

      const oldExpenses = Field.FINANCIAL_EXPENSES.get(state.curr) || [];
      const newExpenses = oldExpenses.map((e, i) => {
        if (i === index) {
          let ne = Object.assign({}, e, expense);
          if (ne.percent != null && grossIncome) {
            ne.value = grossIncome * ne.percent / 100.0;
          }
          if ("percent" in ne && typeof ne.percent === "undefined") {
            delete ne.percent;
          }
          if ("value" in ne && typeof ne.value === "undefined") {
            delete ne.value;
          }
          return ne;
        }
        return e;
      });

      state.curr.financial.expenses = newExpenses;
      state.curr.financial.total_expenses = sumBy(newExpenses, e => e.value || 0);
      state.dirty.push(Field.FINANCIAL_EXPENSES.path);
      state.dirty.push(Field.FINANCIAL_TOTAL_EXPENSES.path);
    },
    moveExpense: (state, action) => {
      const {from, to} = action.payload;
      const e = state.curr.financial.expenses[from];
      state.curr.financial.expenses.splice(from, 1);
      state.curr.financial.expenses.splice(to, 0, e);
      state.dirty.push(Field.FINANCIAL_EXPENSES.path);
    },

    addRateJump: (state, action) => {
      state.curr.lease.rate_jumps.push({id: nanoid(), month: '', value: ''});
      state.dirty.push(Field.LEASE_RATE_JUMPS.path);
    },
    removeRateJump: (state, action) => {
      const jump = action.payload;
      state.curr.lease.rate_jumps = state.curr.lease.rate_jumps.filter(it => it.id !== jump.id);
      state.dirty.push(Field.LEASE_RATE_JUMPS.path);
    },
    updateRateJump: (state, action) => {
      const {id, jump} = action.payload;
      state.curr.lease.rate_jumps = state.curr.lease.rate_jumps.map(
        j => j.id === id ? Object.assign({}, j, jump) : j)
      state.dirty.push(Field.LEASE_RATE_JUMPS.path);
    },

    addRevenue: (state, action) => {
      if (!state.curr.financial.other_revenue) state.curr.financial.other_revenue = [];
      state.curr.financial.other_revenue.push({id: nanoid(), title: '', value: 0})
      state.dirty.push(Field.FINANCIAL_OTHER_REVENUE.path);
    },
    removeRevenue: (state, action) => {
      state.curr.financial.other_revenue =
        (state.curr.financial.other_revenue||[]).filter(it => it.id !== action.payload.id);
      state.dirty.push(Field.FINANCIAL_OTHER_REVENUE.path);
    },
    updateRevenue: (state, action) => {
      const {item, update} = action.payload;
      state.curr.financial.other_revenue =
        (state.curr.financial.other_revenue||[]).map(r => r.id === item.id ?  Object.assign({}, r, update) : r);
      state.dirty.push(Field.FINANCIAL_OTHER_REVENUE.path);
    },
    moveRevenue: (state, action) => {
      const {from, to} = action.payload;
      const e = state.curr.financial.other_revenue[from];
      state.curr.financial.other_revenue.splice(from, 1);
      state.curr.financial.other_revenue.splice(to, 0, e);
      state.dirty.push(Field.FINANCIAL_OTHER_REVENUE.path);
    },

    selectPhoto: (state, action) => {
      state.photo = action.payload;
    },

    promotePhoto: (state, action) => {
      let photos = state.curr.photos || [];
      const p = photos.findIndex(it => it.id === action.payload.id);
      if (p > 0) {
        photos = photos.slice();
        photos.splice(p, 1);
        photos.unshift(action.payload);
        state.curr.photos = photos;
        state.dirty.push('photos');
        state.photo = 0;
      }
    },

    setPhotoTags: (state, action) => {
      const {photo, tags} = action.payload;
      state.curr.photos[photo].tags = tags;
      state.dirty.push('photos');
    },

    setPhotoCategory: (state, action) => {
      const {photo, category, moveToFront} = action.payload;
      state.curr.photos[photo].category = category;

      if (moveToFront) {
        const i = state.curr.photos.findIndex(p => p.category === category);
        if (i > -1 && i < photo) {
          const p = state.curr.photos[photo];
          state.curr.photos.splice(photo, 1);
          state.curr.photos.splice(i, 0, p);
          state.photo = i;
        }
      }
      state.dirty.push('photos');
    },

    setPhotoOrientation: (state, action) => {
      const {photo, orientation} = action.payload;
      state.curr.photos[photo].orientation = orientation;
      state.dirty.push('photos');
    },
    compAdded: (state, action) => {
      const {comp, userId} = action.payload;
      if ((comp.users||[]).includes(userId)) {
        if (!state.recent.mine) state.recent.mine = [];
        state.recent.mine.unshift(comp);
      }
      else {
        if (!state.recent.team) state.recent.team = [];
        state.recent.team.unshift(comp);
      }
    },

    compModified: (state, action) => {
      const {comp, userId} = action.payload;
      if ((comp.users||[]).includes(userId)) {
        state.recent.mine = (state.recent.mine||[]).filter(c => c.id !== comp.id);
        state.recent.mine.unshift(comp);
      }
      else {
        state.recent.team = (state.recent.team||[]).filter(c => c.id !== comp.id);
        state.recent.team.unshift(comp);
      }
    },

    compRemoved: (state, action) => {
      state.recent.mine = (state.recent.mine||[]).filter(c => c.id !== action.payload.id);
      state.recent.team = (state.recent.team||[]).filter(c => c.id !== action.payload.id);
    },

    sortComps: (state, action) => {
      const {field, order} = action.payload;
      state.list.page = -1;
      state.list.sort = {field: field.path, order}
    },

    addFilter: (state, action) => {
      const {field, value, op} = action.payload;
      state.list.page = -1;
      state.list.filters.push({
        field: field.path,
        value, op
      });
    },

    setFilter: (state, action) => {
      const {field, value, op} = action.payload;
      state.list.page = -1;
      state.list.filters = (state.list.filters||[]).filter(f => f.field !== field.path);
      state.list.filters.push({
        field: field.path,
        value, op
      });
    },

    removeFilter: (state, action) => {
      state.list.filters = state.list.filters.filter(it => it.field !== action.payload.path);
      state.list.page = -1;
    },

    refreshComps: (state, action) => {
      state.list.page = -1;
    },

    toggleDetachedField: (state, action) => {
      const field = action.payload.path;
      if (!state.curr.detached) state.curr.detached = [];

      if (state.curr.detached.includes(field)) {
        state.curr.detached = state.curr.detached.filter(it => it !== field);
      } else {
        state.curr.detached.push(field)
      }
      state.dirty.push('detached');
    },

    togglePreview: (state, action) => {
      state.preview.show = !state.preview.show;
    },
    setPreview: (state, action) => {
      state.preview.show = action.payload;
    },
    setPreviewView: (state, action) => {
      state.preview.view = action.payload;
    },
    setHighlight: (state, action) => {
      state.highlight = action.payload;
    },

    setMapMoving: (state, action) => {
      state.map.moving = action.payload === true;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(addComp.pending, (state, action) => {
        state.error = null;
      })
      .addCase(addComp.fulfilled, (state, action) => {
        const {ref, comp} = action.payload;
        state.curr = {
          ...comp,
          id: ref.id
        };
        state.ref = ref;
        state.dirty = [];
        state.error = null;
      })
      .addCase(addComp.rejected, (state, action) => {
         state.error = action.payload;
      })
      .addCase(saveComp.pending, (state, action) => {
        state.error = null;
       })
      .addCase(saveComp.fulfilled, (state, action) => {
        const {updated, updated_by} = action.payload;
        state.curr.updated = updated ? updated : state.curr.updated;
        state.curr.updated_by = updated_by ? updated_by : state.curr.updated_by;
        state.dirty = [];
        // saved: {$set: action.status},
      })
      .addCase(saveComp.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(loadComp.fulfilled, (state, action) => {
        state.curr = Comp.create(action.payload);
        state.dirty = [];
      })
      .addCase(cloneComp.fulfilled, (state, action) => {
        // TODO: something?
      })
      .addCase(deleteComp.fulfilled, (state, action) => {
        if (state.list.values.length > 0) {
          const i = state.list.values.findIndex(it => it.id === action.payload);
          if (i > -1) {
            state.list.values.splice(i, 1);
          }

          const j = (state.recent?.mine||[]).findIndex(it => it.id === action.payload);
          if (j > -1) {
            state.recent.mine.splice(j, 1)
          }
        }
      })
      .addCase(copyData.fulfilled, (state, action) => {
        if (action.payload) {
          state.curr = action.payload;
        }
      })
      .addCase(renderMap.fulfilled, (state, action) => {
        state.curr.map = action.payload;
        state.dirty.push('map');
      })
      .addCase(loadPhotos.fulfilled, (state, action) => {
        state.photos = orderedBy(action.payload, [
          (p) => (p.meta.customMetadata && p.meta.customMetadata.order) || 0,
          (p) => (p.meta.customMetadata && p.meta.customMetadata.orderTimestamp) || 0,
          (p) => p.meta.timeCreated
        ], ['desc', 'desc', 'asc']);
      })
      .addCase(addPhoto.fulfilled, (state, action) => {
        if (!state.curr.photos) state.curr.photos = [];
        state.curr.photos.push(action.payload);
        state.dirty.push('photos');
      })
      .addCase(removePhoto.fulfilled, (state, action) => {
        if (!state.curr.photos) state.curr.photos = [];
        const p = state.curr.photos.findIndex(it => it.id === action.payload.id);
        if (p > -1) {
          state.curr.photos.splice(p, 1);
          state.photo = state.curr.photo === p ? 0 : state.curr.photo;
          state.dirty.push('photos');
        }
      })
      .addCase(loadDocuments.fulfilled, (state, action) => {
        state.docs = orderedBy(action.payload, [
          (p) => p.meta.timeCreated
        ], ['desc']);
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        if (!state.curr.docs) state.curr.docs = [];
        state.curr.docs.push(action.payload);
        state.dirty.push('docs');
      })
      .addCase(removeDocuments.fulfilled, (state, action) => {
        const docIds = action.payload.map(it => it.id);
        state.curr.docs = (state.curr.docs||[]).filter(it => !docIds.includes(it.id));
        state.dirty.push('docs');
      })
      .addCase(loadRecentComps.fulfilled, (state, action) => {
        state.recent = (action.payload||[]);
      })
      .addCase(pageComps.fulfilled, (state, action) => {
        const {page, docs} = action.payload;

        const list = state.list;
        const forward = page > list.page;

        if (forward) {
          list.stack.push(list.first);
        }
        else {
          list.stack.pop();
        }

        list.page = page;
        list.values = (docs||[]).map(doc => Comp.create(doc));
        list.last = last(docs);
        list.first = first(docs);
      })
  }
});

export const {
  setComp, clearComp, setType, setAddress, clearAddress, setGeo, clearGeo, setField, clearField, setCopyData, setRoundToDollar,
  addNote, removeNote, toggleDetachedField, addExpense, removeExpense, updateExpense, moveExpense, addRateJump, removeRateJump, updateRateJump,
  addRevenue, removeRevenue, updateRevenue, moveRevenue,
  selectPhoto, promotePhoto, setPhotoTags, setPhotoCategory, setPhotoOrientation,
  compAdded, compModified, compRemoved, sortComps, addFilter, setFilter, removeFilter, refreshComps,
  togglePreview, setPreview, setPreviewView, setHighlight, setMapMoving
} = comp.actions;

export default comp.reducer;
