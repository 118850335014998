import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Spinner from "../util/Spinner";
import Page from "../util/Page";
import Wizard from "../util/Wizard";
import PlanLimitAlert from "../util/PlanLimitAlert";
import {addOrSaveComp, setComp} from './state';
import CompTypeStep from "./TypeStep";
import PropertyInfoStep from "./AddressStep";
import EditStep from "./EditStep";
import {propertyNameShort} from "../model/propertyTypes";
import {isCompLimitReachedSelector} from "../app/selectors";
import {Comp} from "../model/objects";

const pageSteps = () => ["Choose Comp Type", "Enter Property Address", "Fill Out Comp Info"];

const stepContent = (step) => {
    switch(step) {
        case 0:
            return <CompTypeStep/>;
        case 1:
            return <PropertyInfoStep/>;
        case 2:
            return <EditStep/>;
        default:
            return "Unknown";
    }
};

const stepInstruct = (step) => {
    switch(step) {
        case 0:
            return "Choose a property type for the new comparable.";
        case 1:
            return "Enter the property address by typing the address into the search bar.";
        default:
            return "Fill in the details for the new comparable.";
    }
};

const AddPage = () => {
    const comp = useSelector(state => state.comp.curr);
    const org = useSelector(state => state.app.org);
    const isLimitReached = useSelector(isCompLimitReachedSelector);
    const isMapMoving = useSelector(state => state.comp.map?.moving === true);
    const dispatch = useDispatch();

    const isReady = org && comp;

    const setNewComp = () => dispatch(setComp(Comp.new()));

    React.useEffect(() => {
        setNewComp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ref = useRef();

    const stepLabel = (step, activeStep) => {
        switch(step) {
            case 0:
                return activeStep > step ? propertyNameShort(comp.type) : "Choose Comp Type";
            case 1:
                return activeStep > step ? comp.address.street : "Enter Property Address";
            case 2:
                return "Fill Out Comp Info";
            default:
                return "";
        }
    };

    const stepIsComplete = (step) => {
        switch(step) {
            case 0:
                return comp.type;
            case 1:
                return comp.address.street && comp.address.city && comp.address.region
                    && comp.address.country && comp.geo && !isMapMoving;
            case 2:
                return true;
            default:
                return false;
        }
    };

    const finish = () => {
        dispatch(addOrSaveComp(comp)).then(() => {
            setNewComp();
            ref.current.reset();
        })
    };

    return (
        <Page className="AddPage">
            <Container sx={{pt: 2}}>
                {!isReady && <Spinner/>}
                {isReady && isLimitReached && <Box sx={{pt: 4}}>
                    <PlanLimitAlert title="Comp Limit Reached" message="You have reached the maximum number of comps that your plan allows for."
                        upgradeMessage="Upgrade your plan to create more comps."/>
                </Box>}
                {isReady && !isLimitReached && <Wizard ref={ref} steps={pageSteps()} {...{stepContent, stepInstruct, stepLabel, stepIsComplete, finish}} />}
            </Container>
        </Page>
    );
};

export default AddPage;
